<template>
  <a-form-model style="background: #ffffff;min-height:900px;padding-top: 20px;"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol" 
  >
    <a-form-model-item ref="oldPSW" label="原密码：" prop="oldPSW">
      <a-input
        type="password"
        placeholder="请输入原密码"
        v-model="form.oldPSW"
        @blur="
          () => {
            $refs.oldPSW.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item ref="newPSW" label="新密码：" prop="newPSW">
      <a-input
        type="password"
        placeholder="请输入新密码"
        v-model="form.newPSW"
        @blur="
          () => {
            $refs.newPSW.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item ref="confirmPSW" label="确认密码：" prop="confirmPSW">
      <a-input
        type="password"
        placeholder="请确认新密码"
        v-model="form.confirmPSW"
        @blur="
          () => {
            $refs.confirmPSW.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 8, offset: 8 }" style="text-align: center;">
      <a-button type="primary" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetForm">
        取消
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { updatePwd } from "@/api/permission/user/index";
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      form: {
        oldPSW: "",
        newPSW: "",
        confirmPSW: "",
      },
      rules: {
        oldPSW: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        newPSW: [{ required: false, message: "请输入新密码", trigger: "blur" }],
        confirmPSW: [{ required: false, message: "请确认新密码", trigger: "blur" }]
      }
    };
  },
  methods: {
    onSubmit() {
      let _this = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(_this.form.confirmPSW == _this.form.newPSW){
            var formData = new FormData();
            formData.append("newPassword", _this.form.newPSW);
            formData.append("oldPassword", _this.form.oldPSW);
            updatePwd(formData).then(res => {
              console.log(res);
              if (res.code == 0) {
                _this.$message.success("密码修改成功", function() {
                  _this.$router.push({ path: "/" });
                });
              } else {
                _this.$message.error(res.msg);
              }
            });
          }else{
            _this.$message.error('新密码和确认密码不一致！');
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    }
  }
};
</script>

<style scoped>
  
</style>
