import axios from "axios";
import { conf } from "./setBaseUrl";
// import router from '@/router/'
// import {
//   getToken
//   // setToken
// } from "@/utils/auth";
// import message from "@/utils/pnotify";
console.log(window.location.host);

let baseUrl = "http://dev.waterss.cn:9120/api/ucp";
// let baseUrl = "https://liaoning.waterss.cn/api/ucp";
// let baseUrl = "https://shanxi.waterss.cn/api/ucp";

// process.env.VUE_APP_BASE_URL
// console.log(process.env);
// let baseUrl = process.env.VUE_APP_BASE_URL;
// let baseUrl = `/api/ucp`;

const _http = axios.create({
  baseURL: baseUrl
});
_http.interceptors.request.use(
  config => {

    if (localStorage.getItem("token")) {
      config.headers["X-Access-Token"] = localStorage.getItem("token");
    }
    switch (config.url) {
      case "/system/user/list":
      case "/system/menu/moveMenu":
        config.headers["Content-Type"] =
          "application/x-www-form-urlencoded;charset=UTF-8";
        break;
      case "/system/common/upload":
      case "/system/user/profile/updatePwd":
      case "/account/changePassword":
      case "/account/sendMessage":
        config.headers["Content-Type"] = "multipart/form-data";
        break;
      default:
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        break;
    }
    return config;
  },
  error => {
    console.log("request", error);

    return Promise.reject(error);
  }
);

// // 响应拦截器
// _http.interceptors.response.use(
//   response => {
//     console.log(response, "response响应拦截器");
//     // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
//     //否则的话抛出错误
//     if (response.status === 200) {
//       // this.$message.success("This is a success message");
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
//   error => {
//     console.log(error, "error");
//     if (error.response.data.status) {
//       return Promise.reject(error);
//       // switch (error.response.data.status) {
//       //   // 401:未登录;未登录则跳转登录页面，并携带当前页面的路径;在登录成功后返回当前页面，这一步需要在登录页操作。
//       //   // case 401:
//       //   //   router.replace({
//       //   //     path: "/login",
//       //   //     query: {
//       //   //       redirect: router.currentRoute.fullPath
//       //   //     }
//       //   //   });
//       //   //   break;
//       //   // 403:token过期;登录过期对用户进行提示;清除本地token和清空vuex中token对象;跳转登录页面
//       //   // case 403:
//       //   //   Message({
//       //   //     showClose: true,
//       //   //     message: "登录过期，请重新登录",
//       //   //     duration: 1000,
//       //   //     type: "warning"
//       //   //   });
//       //   //   //清除token
//       //   //   localStorage.removeItem("userToken");
//       //   //   store.commit("LOGIN_OUT", null);
//       //   //   //跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
//       //   //   setTimeout(() => {
//       //   //     router.replace({
//       //   //       path: "/login",
//       //   //       query: {
//       //   //         redirect: router.currentRoute.fullPath
//       //   //       }
//       //   //     });
//       //   //   }, 1000);
//       //   //   break;
//       //   //404请求不存在
//       //   case 404:
//       //     console.log(404);
//       //     // this.$message.error("网络请求不存在");
//       //     break;
//       //   //404请求不存在
//       //   case 500:
//       //     console.log(500);
//       //     // this.$message.error("500");
//       //     break;
//       //   //其他错误,直接抛出错误提示
//       //   default:
//       //     console.log("default");

//       //   // this.$message.error("500");
//       // }
//     }
//     return Promise.reject(error);
//   }
// );

_http.interceptors.response.use(
  response => {
    console.log(response);

    var accessToken = response.headers['X-Access-Token']
    if(accessToken) {
      console.log("accessToken==========="+accessToken)
      localStorage.setItem("token",accessToken) ;
    }

    const responseData = response.data ;

    if(responseData.code == 9001 ) {

      let host = window.location.origin;
      window.location.replace(host);
    }
    return response;
  },
  error => {
    console.log("response", error);

    if(error.response.status == 401 ) {
      let host = window.location.origin;
      window.location.replace(host);
    }
    return Promise.reject(error.response.data);
  }
);

async function http(options) {
  const response = await _http(options);

  if (response.status == 200) {
    let result = response.data;
    if (result.data?.token && result.code == 0) {

      localStorage.setItem("token", result.data.token);
      localStorage.setItem("userName", result.data.nick_name);
    }
    return result;
  }
  if (response.status == 401) {
    localStorage.removeItem("token");
    let host = window.location.origin;
    window.location.replace(host);
  }
  if (response.status == 200 || response.error == 0) {
    return response.data;
  } else {
    if (response.status == 500) {
      throw new Error(response.error);
    }
  }
  //
  // if (Number(response.status_code) === 102 || Number(response.status_code) === 104 || Number(response.status_code) === 101 || Number(response.status_code) === 105) {
  //    message.error('请重新登录')
  //     setToken('')
  //     location.reload()
  // } else {
  //     //105账号密码错误
  //     if (Number(response.status_code) >= 200 && Number(response.status_code) < 300) {
  //         return response.content
  //     } else if (Number(response.status_code) == 403) {
  //         message.error(response.message)
  //         router.push('/403')
  //         throw new Error(response.message)
  //     } else {
  //         message.error(response.message)
  //         throw new Error(response.message)
  //     }
  // }
}

export default http;
