import http from "@/plugins/http";
// 获取列表
export function getUserList(data) {
  return http({
    url: `/system/user/list`,
    data: data,
    method: "POST"
  });
}

// 新增
export function createUser(data) {
  console.log(data);
  return http({
    url: `/system/user`,
    data: data,
    method: "POST"
  });
}

// 详情
export function showUser(data) {
    console.log(data);
    return http({
        url: `/system/user/${data.userId}`,
        method: 'GET',
    })
}

// 编辑
export function editUser(data) {
    console.log(data);
    return http({
        url: `/system/user`,
        data:data,
        method: 'PUT',
    })
}

// 删除
export function deleteUser(data) {
  console.log(data);
  return http({
    url: `/system/user/${data.userIds}`,
    method: "DELETE"
  });
}

// 编辑
export function changeStatus(data) {
    console.log(data);
    return http({
        url: `/system/user/changeStatus`,
        data:data,
        method: 'PUT',
    })
}

// 重置密码
export function updatePwd(data) {
  console.log(data);
  return http({
    url: `/system/user/profile/updatePwd`,
    data:data,
    method: 'POST',
  })
}
// 修改密码
export function changePassword(data) {
  console.log(data);
  return http({
    url: `/account/changePassword`,
    data:data,
    method: 'POST',
  })
}
// 发送短信验证码密码
export function sendMessage(data) {
  console.log(data);
  return http({
    url: `/account/sendMessage`,
    data:data,
    method: 'POST',
  })
}