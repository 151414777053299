let baseUrl = "";
let appKey = "1400294099";
switch (process.env.NODE_ENV) {
  case "development": //前端开发环境
    baseUrl = "http://dev.waterss.cn:9120/api/ucp";
    break;
  case "alpha": // dev环境
    baseUrl = "http://dev.waterss.cn:9120/api/ucp";
    break;
  case "test": // test环境
    baseUrl = "http://dev.waterss.cn:9120/api/ucp";
    break;
  case "production": //正式环境
    baseUrl = "http://dev.waterss.cn:9120/api/ucp";
    break;
}

export default baseUrl;
export const conf = {
  rongCloud: {
    appKey: appKey
  }
};
